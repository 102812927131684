<template>
  <v-form
    ref="form"
    class="mb-4 text-center"
    data-cy="profile-setting-form"
    lazy-validation
    @submit.prevent="onProfileSettingFormSubmit()"
  >
    <v-row
      dense
      align="center"
    >
      <v-col>
        <v-text-field
          v-model="formData.fullName"
          :label="$t(`fullName`)"
          :rules="fullNameRules"
          required
          data-cy="full-name-input"
        />
        <v-text-field
          v-model="formData.email"
          :label="$t(`email`)"
          :rules="emailRules"
          required
          data-cy="email-input"
          validate-on-blur
        />
        <v-select
          v-model="formData.locale"
          :label="$t(`localization`)"
          :items="supportedLanguages"
          data-cy="localization-input"
        />
        <v-text-field
          v-model="formData.password"
          v-bind="dynamicPasswordProps"
          :label="$t('password')"
          required
          :error-messages="passwordErrors"
          autocomplete="new-password"
          data-cy="change-password-input"
          @click:append="togglePasswordVisibility()"
          @blur="validatePasswordOnBlur"
        />
        <v-text-field
          v-model="confirmPassword"
          v-bind="dynamicConfirmPasswordProps"
          :label="$t('confirmPassword')"
          :rules="confirmPasswordRules"
          autocomplete="new-password"
          data-cy="confirm-password-input"
          validate-on-blur
          @click:append="toggleConfirmPasswordVisibility()"
          @paste.prevent="() => {}"
        />
        <template v-if="passwordErrors.length > 0">
          <v-alert
            v-if="passwordWarning"
            dense
            type="warning"
            outlined
            class="core-element-info-message text-left"
          >
            {{ $t(`passwords.warnings.${passwordWarning}`) }}
          </v-alert>
          <v-alert
            v-if="passwordSuggestions.length > 0"
            color="info"
            dense
            :icon="infoIcon"
            outlined
            class="core-element-info-message text-left"
          >
            <template v-if="passwordSuggestions.length === 1">
              {{ $t(`passwords.suggestions.${passwordSuggestions[0]}`) }}
            </template>
            <template v-else>
              <h5>{{ $t('suggestions') }}</h5>
              <ul>
                <li
                  v-for="suggestion in passwordSuggestions"
                  :key="suggestion"
                >
                  {{ $t(`passwords.suggestions.${suggestion}`) }}
                </li>
              </ul>
            </template>
          </v-alert>
        </template>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <div
          v-t="'team.notifications'"
          class="v-card__title justify-center"
        />
        <v-row
          justify="center"
          align="center"
        >
          <v-col
            cols="10"
            :data-cy="allNotificationsSwitchName"
          >
            <v-switch
              v-model="allNotificationsChecked"
              :label="$t(`team.${camelize(allNotificationsSwitchName)}`)"
              inset
              class="mt-0 no-input-details pa-0"
              data-cy="all-notifications-switch"
              @change="onAllNotificationsSwitchChange"
            />
          </v-col>
        </v-row>
        <v-row
          v-for="notification in availableNotifications"
          :key="notification.name"
          justify="center"
          align="center"
        >
          <v-col
            cols="8"
            :data-cy="notification.name"
          >
            <v-switch
              v-model="formData.notifications[notification.name]"
              :label="$t(`team.${camelize(notification.name)}`)"
              inset
              class="mt-0 no-input-details pa-0"
              :data-cy="`notification-switch-${notification.name}`"
              @change="controlAllNotificationsSwitchValue"
            />
          </v-col>
          <v-col cols="2">
            <v-tooltip
              v-if="notification.info"
              top
              class="mr-auto"
            >
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  dark
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span v-t="`team.${notification.info}`" />
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <core-element-errors-message
      :show-errors="showApiErrors"
      :errors="errors"
    />
    <v-btn
      rounded
      color="primary"
      data-cy="save-profile-button"
      type="submit"
      class="mx-auto my-3"
      :disabled="saved"
      :loading="loadingProfileSettingSet"
    >
      {{ $t('save') }}
    </v-btn>
    <span
      v-if="saved"
      class="green--text ml-1"
      data-cy="profile-setting-success-alert"
    >
      <v-icon color="green">{{ doneIcon }}</v-icon>
      {{ $t('saved') }}
    </span>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import {
  LOGOUT_ICON, ROLE_ADMIN, ROLE_NAME,
  ACCESS_LEVELS_ENUM,
  ADMIN_ACCESS_LEVEL,
  ALL_NOTIFICATIONS,
  PERMISSIONS_NAME,
  STATIC_NOTIFICATIONS, DONE_ICON, INFO_ICON
} from '@/constants/app'
import { Trans } from '@/plugins/Translation'
import { validateEmail } from '@/utils/validations'
import { MINIMAL_ALLOWED_PASSWORD_SCORE } from '@/constants/passwordPolicy'
const zxcvbn = () => import('@/utils/passwordValidator')

export default {
  data () {
    return {
      logoutIcon: LOGOUT_ICON,
      adminRole: ROLE_ADMIN,
      formData: {
        locale: '',
        fullName: '',
        email: '',
        notifications: [],
        password: ''
      },
      allNotificationsSwitchName: ALL_NOTIFICATIONS,
      allNotificationsChecked: false,
      loadingPasswordPatch: false,
      saved: false,
      doneIcon: DONE_ICON,
      confirmPassword: '',
      passwordVisible: false,
      confirmPasswordVisible: false,
      passwordErrors: [],
      passwordSuggestions: [],
      passwordWarning: '',
      infoIcon: INFO_ICON
    }
  },
  computed: {
    ...mapState('client', ['clients', 'showApiErrors', 'errors', 'loadingProfileSettingSet']),
    ...mapGetters('client', { clientGetter: 'clientById' }),
    supportedLanguages () {
      return Trans.supportedLanguages.map(language => language.toUpperCase())
    },
    routeClientId () {
      return this.$route.params.clientId
    },
    isPartnerOverviewPage () {
      return (
        this.isPartnerLoggedIn &&
        this.routeClientId === 'my'
      )
    },
    isPartnerLoggedIn () {
      return localStorage.getItem(ROLE_NAME) === ROLE_ADMIN
    },
    availableNotifications () {
      const clientPermissions = JSON.parse(localStorage.getItem(PERMISSIONS_NAME))
      let availableNotifications = STATIC_NOTIFICATIONS.filter(item => item.name !== ALL_NOTIFICATIONS)
      if (clientPermissions.includes(ADMIN_ACCESS_LEVEL)) {
        availableNotifications = [
          ...availableNotifications,
          ...ACCESS_LEVELS_ENUM.reduce((acc, level) => ([
            ...acc,
            ...level.availableNotifications
          ]), [])
        ]
      } else {
        for (const permissionName of clientPermissions) {
          const levelData = ACCESS_LEVELS_ENUM.find(level => level.name === permissionName)
          availableNotifications = [
            ...availableNotifications,
            ...levelData.availableNotifications
          ]
        }
      }
      return availableNotifications
    },
    fullNameRules () {
      return [v => !!v || this.$i18n.t('errors.fieldIsRequired')]
    },
    confirmPasswordRules () {
      return [v => !v || this.confirmPassword === this.formData.password || this.$i18n.t('passwordsDoesntMatch')]
    },
    emailRules () {
      return [
        v => !!v || this.$i18n.t('errors.fieldIsRequired'),
        v => validateEmail(v) || this.$i18n.t('errors.wrongEmailFormat')
      ]
    },
    dynamicPasswordProps () {
      return this.passwordVisible
        ? { appendIcon: 'mdi-eye', type: 'text' }
        : { appendIcon: 'mdi-eye-off', type: 'password' }
    },
    dynamicConfirmPasswordProps () {
      return this.confirmPasswordVisible
        ? { appendIcon: 'mdi-eye', type: 'text' }
        : { appendIcon: 'mdi-eye-off', type: 'password' }
    },
    password () {
      return this.formData.password
    }
  },
  watch: {
    formData: {
      handler () {
        if (this.saved) {
          this.saved = false
        }
      },
      deep: true
    },
    async password (newVal, oldVal) {
      if (newVal !== '' && oldVal !== newVal) {
        const valid = await this.validatePassword(newVal)
        if (valid) {
          if (this.passwordErrors && Array.isArray(this.passwordErrors) && this.passwordErrors.length > 0) {
            this.passwordErrors = []
          }
          if (this.passwordSuggestions &&
            Array.isArray(this.passwordSuggestions) &&
            this.passwordSuggestions.length > 0) {
            this.passwordSuggestions = []
          }
          if (this.passwordWarning !== '') {
            this.passwordWarning = ''
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('client', ['setClientProfileSetting']),
    ...mapMutations('client', ['setErrors']),
    async validatePasswordOnBlur () {
      if (this.formData.password !== '') {
        const valid = await this.validatePassword(this.formData.password)
        if (!valid) {
          this.passwordErrors = [this.$i18n.t('errors.passwordIsTooEasy')]
        }
      }
    },
    validatePassword (password) {
      return zxcvbn().then(zxcvbn => {
        const result = zxcvbn.passwordValidator(password)
        this.passwordSuggestions = result.feedback && result.feedback.suggestions
          ? result.feedback.suggestions.map(v => this.$options.filters.camelize(v))
          : []
        this.passwordWarning = result.feedback && result.feedback.warning
          ? this.$options.filters.camelize(result.feedback.warning)
          : ''
        return result.score >= MINIMAL_ALLOWED_PASSWORD_SCORE
      })
    },
    async onProfileSettingFormSubmit () {
      try {
        const validForm = this.$refs.form.validate()
        let validPassword = true
        if (this.formData.password) {
          if (this.formData.password !== this.confirmPassword) {
            validPassword = false
          } else {
            validPassword = await this.validatePassword(this.formData.password)
          }
        }
        if (validForm && validPassword) {
          const responseData = await this.setClientProfileSetting({ clientId: this.routeClientId, data: this.formData })
          const lowerCasedNewLocale = responseData.locale.toLowerCase()

          if (lowerCasedNewLocale !== this.$route.params.locale) {
            const to = this.$router.resolve({ params: { locale: lowerCasedNewLocale } })
            await Trans.changeLanguage(lowerCasedNewLocale)
            await this.$router.push(to.location)
          }

          if (!this.showApiErrors) {
            this.resetForm()
            this.$nextTick(() => {
              this.saved = true
            })
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    resetForm () {
      this.$refs.form.resetValidation()

      this.formData = {
        locale: this.clients[this.routeClientId].locale.toUpperCase(),
        fullName: this.clients[this.routeClientId].fullName,
        email: this.clients[this.routeClientId].email,
        password: '',
        notifications: this.clients[this.routeClientId].notifications.reduce((acc, notification) => ({
          ...acc,
          [notification]: true
        }), {})
      }

      this.confirmPassword = ''
      this.passwordSuggestions = []
      this.passwordWarning = ''
      this.passwordErrors = []

      this.controlAllNotificationsSwitchValue()
      this.setErrors({})
    },
    onAllNotificationsSwitchChange (value) {
      if (value) {
        this.formData.notifications = this.availableNotifications.reduce((acc, notification) => ({
          ...acc,
          [notification.name]: true
        }), [])
      } else {
        this.formData.notifications = []
      }
    },
    controlAllNotificationsSwitchValue () {
      let allNotificationsChecked = true
      for (const notification of this.availableNotifications) {
        if (!this.formData.notifications[notification.name]) {
          allNotificationsChecked = false
          break
        }
      }
      this.allNotificationsChecked = allNotificationsChecked
    },
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    togglePasswordVisibility () {
      this.passwordVisible = !this.passwordVisible
    },
    toggleConfirmPasswordVisibility () {
      this.confirmPasswordVisible = !this.confirmPasswordVisible
    }
  }
}
</script>
